import React, { useState, useEffect } from "react";
import { Row, Col, Progress, Modal, Skeleton } from "antd";
import {
  Container,
  StyledCard,
  Btn,
  Duration,
  PopupData,
  Scroller,
} from "./index.style";
import moment from "moment";
import LoadingSpinner from "../../common/Loading/LoadingSpinner";
import { Syllabus } from "./Syllabus";
import { useSelector, useDispatch } from "react-redux";
import { joinLiveClass } from "../../redux/liveClassSlice";
import { Result } from "antd";
import { Link } from "react-router-dom";
import CustomImage from "../../common/images";
import { useNavigate } from "react-router-dom";

const formatDate = (classStartDate) => {
  const date = new Date(classStartDate);
  const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

const linkedName = (inputUrl) => inputUrl.match(/\/in\/([^/]+)/)?.[1] || null;

const extractLiveClassValues = (course) => ({
  ...course,
  courseLink: `/course_detail?name=${course?.courseName}` || "#",
  classDuration: course?.classDuration || "46 minutes",
  className: course?.className || "",
  description: course?.description || "",
  lecturerId: course?.lecturerId || null,
  courseData: course?.courseData || null,
  lecturerName: course?.lecturerName || null,
  lecturerImageURL: course?.lecturerImageURL || null,
  lecturerLinkedinLink: course?.lecturerLinkedinLink || null,
  lecturerSpecialisation: course?.lecturerSpecialisation || null,
});

const ModalComponent = ({
  type,
  visible,
  style,
  handleModal,
  children,
  title,
  width,
}) => (
  <Modal
    open={visible}
    onOk={handleModal(type, "ok")}
    onCancel={handleModal(type, "cancel")}
    footer={null}
    width={width}
    style={style}
    bodyStyle={{ height: "470px" }}
  >
    <PopupData>
      <h3 className="text-center custom-heading">{title}</h3>
      {children}
    </PopupData>
  </Modal>
);

const ButtonWithLoading = ({
  loading,
  disabled,
  enrollmentOpen = false,
  onClick,
  classStatus,
  children,
}) => {
  const isLoading = loading && disabled;
  const isclassStatus = classStatus === "Class Ended";
  return (
    <Btn
      onClick={onClick}
      htmlType="submit"
      type="submit"
      loading={isLoading}
      disabled={isclassStatus ? true : !(disabled && enrollmentOpen)}
    >
      {children}
    </Btn>
  );
};

const LiveClassesCard = ({ liveClass, userProfile }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [modalState, setModalState] = useState({
    syllabus: false,
    joinClass: false,
    courseId: null,
    className: null,
    courseData: null,
  });
  const { joinLoading, enrollingLiveClass } = useSelector(
    (state) => state?.liveClass
  );
  const { courseLoading } = useSelector((state) => state.course);
  const [loadingStates, setLoadingStates] = useState({});

  useEffect(() => {
    if (liveClass?.length >= 0) setLoading(false);
  }, [liveClass]);

  const showModal = (modalType, course) => {
    setModalState({
      ...modalState,
      [modalType]: true,
      courseId: course.courseId,
      courseData: course?.courseData,
      className: course.className,
    });
    setLoadingStates({ ...loadingStates, [course._id]: true });
    if (modalType === "joinClass") enrolling(course);
  };

  const onReset = async () => setLoadingStates({});
  const subscriptionAvailableDate = moment().add(14, "days");
  const enrolling = async (course) => {
    const { _id, courseId } = course;
    const dataObject = { liveClassId: _id, courseId };
    await dispatch(joinLiveClass({ dataObject, onReset }));
  };

  const handleModal = (modalType, action) => () => {
    if (action === "ok" || action === "cancel")
      setModalState({
        ...modalState,
        syllabus: false,
        joinClass: false,
        courseData: null,
      });
  };

  const renderJoinClassButton = (course) => {
    // const isDisabled = userProfile?.course?.some((e) => e?.courseId === course?.courseId) || !true;
    const isDisabled =
      userProfile?.subscription?.liveClassAccess?.some((e) => {
        const expiryDate = moment(e?.expiryDate);
        return (
          e?.courseId === course?.courseId &&
          expiryDate.isAfter(subscriptionAvailableDate)
        );
      }) || !true;

    const isEnrolled = course?.Student?.some(
      (e) => e?.userId === userProfile?._id
    );
    return (
      <div>
        <ButtonWithLoading
          onClick={() => showModal("joinClass", course)}
          disabled={isDisabled}
          enrollmentOpen={course?.enrollmentOpen}
          classStatus={course?.classStatus}
          loading={joinLoading}
        >
          {isEnrolled ? "Enrolled" : "Join Class"}
        </ButtonWithLoading>
        {!isDisabled && (
          <p style={{ color: "red" }}>
            You must possess a valid subscription with at least 15 days
            remaining until its expiration date,{" "}
            <Link
              to="/learn_sql_power_bi_analytics_online_training_cost"
              style={{ textDecoration: "underline" }}
            >
              {" "}
              click here
            </Link>
          </p>
        )}
      </div>
    );
  };

  const renderEnrollmentResult = () => {
    if (joinLoading)
      return (
        <div
          style={{
            alignItems: "center",
          }}
        >
          <Skeleton
            active
            avatar
            paragraph={{
              rows: 8,
            }}
          />
        </div>
      );
    const success =
      enrollingLiveClass && [200, 201, 400].includes(enrollingLiveClass.code);
    return (
      <center>
        <Result
          status={success ? "success" : "info"}
          title={
            <h3 className="text-center custom-heading pb-3">
              {success ? "Enrollment Successful." : "Enrollment Failed."}
            </h3>
          }
          subTitle={
            success ? (
              <div style={{ color: "#313131" }}>
                You have been successfully enrolled in the{" "}
                <strong>{modalState.className}</strong> class. You can join this
                class now.
              </div>
            ) : (
              "Ops. Enrollment to this class could not be performed at the moment. Please try again later."
            )
          }
          extra={
            <div className="d-flex row justify-content-center">
              {success ? (
                <Btn
                  disabled={
                    !(
                      userProfile?.course?.some(
                        (e) => e.courseId === modalState.courseId
                      ) || !true
                    )
                  }
                  onClick={() => {
                    window.location.href = "https://wa.me/+971504614868";
                  }}
                >
                  Join Class
                </Btn>
              ) : (
                <Btn type="primary" onClick={() => navigate("/permissions")}>
                  Continue
                </Btn>
              )}
            </div>
          }
        />
      </center>
    );
  };

  return (
    <Container>
      <div>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="container">
            <div className="row gy-5 mb-5 pt-5">
              {liveClass?.map((course, index) => {
                const extractedValues = extractLiveClassValues(course);
                return (
                  <div key={index} className="col-12">
                    <StyledCard
                      className="mt-1 mb-1 pt-1 pb-1"
                      hoverable
                      style={{ width: "100%" }}
                    >
                      <Row gutter={16}>
                        <Col
                          xs={12}
                          lg={4}
                          className="order-1 order-sm-1 order-lg-1 d-flex flex-column align-items-center"
                        >
                          {extractedValues?.lecturerImageURL && (
                            <CustomImage
                              className="course-image"
                              alt="i"
                              width="125px"
                              height="125px"
                              onError={(e) => {
                                e.target.style.display = "none";
                              }}
                              src={extractedValues?.lecturerImageURL}
                            />
                          )}
                          {extractedValues?.lecturerName && (
                            <div className="mb-3 d-flex flex-column align-items-center">
                              <p className="mb-1 lecturer-name">
                                {extractedValues?.lecturerName}
                              </p>
                              <p className="mb-1 lecturer-specialisation">
                                {extractedValues?.lecturerSpecialisation}
                              </p>
                              <Link
                                to={extractedValues?.lecturerLinkedinLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="mt-4 d-flex align-items-center justify-content-center lecturer-link"
                              >
                                <span style={{ marginBottom: "3px" }}>
                                  <CustomImage
                                    src="/icons/lc_linkedin.svg"
                                    alt="LinkedIn"
                                    width="18px"
                                    height="18px"
                                  />{" "}
                                </span>
                                <span className="lecturerlink">
                                  /
                                  {linkedName(
                                    extractedValues?.lecturerLinkedinLink
                                  )}
                                </span>
                              </Link>
                            </div>
                          )}
                        </Col>
                        <Col
                          className="order-2 order-sm-2 order-lg-3"
                          xs={12}
                          lg={6}
                        >
                          <ul
                            style={{ padding: "0px", paddingLeft: "16px" }}
                            className="d-lg-none d-block"
                          >
                            <li
                              className="class-status"
                              style={{
                                color:
                                  extractedValues?.classStatus === "In Progress"
                                    ? "#00AC47"
                                    : undefined,
                              }}
                            >
                              {extractedValues?.classStatus}
                            </li>
                          </ul>
                          <div className="showInfo">
                            {extractedValues?.classStatus ===
                              "Class starting soon" && (
                              <>
                                <p className="students-joined">
                                  {extractedValues
                                    ? `${
                                        extractedValues?.Student?.length || 0
                                      }/${
                                        extractedValues?.minimumStudentRequired ||
                                        30
                                      } Students Joined`
                                    : "Students Joined Information Not Available"}
                                </p>
                                {extractedValues && (
                                  <div className="class-progress">
                                    <Progress
                                      percent={
                                        (extractedValues?.Student?.length /
                                          30) *
                                        100
                                      }
                                      status="active"
                                      strokeColor={{
                                        "0%": "#D39331",
                                        "100%": "#D39331",
                                      }}
                                      size="small"
                                      showInfo={false}
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          <div className="class-links">
                            <div
                              onClick={() => {
                                showModal("syllabus", extractedValues);
                              }}
                            >
                              <CustomImage
                                className="img"
                                src="/icons/lc_material-symbols.svg"
                                alt="Syllabus"
                                width="22px"
                                height="22px"
                                style={{ paddingBottom: "1px" }}
                              />
                              <span className="class-link">Syllabus</span>
                            </div>
                            <ModalComponent
                              type="syllabus"
                              visible={modalState.syllabus}
                              handleModal={handleModal}
                              title="Syllabus"
                              width={440}
                              style={{ top: "50px" }}
                            >
                              <Scroller
                                style={{ height: "300px", overflow: "auto" }}
                              >
                                {modalState.syllabus && modalState.courseId && (
                                  <Syllabus
                                    courseData={modalState?.courseData}
                                  />
                                )}
                              </Scroller>
                              <div className="d-flex row justify-content-center">
                                <Btn loading={courseLoading} type="primary">
                                  Enroll now
                                </Btn>
                              </div>
                            </ModalComponent>
                          </div>
                          <div className="class-links">
                            <Link to={extractedValues?.courseLink}>
                              <CustomImage
                                className="img"
                                src="/icons/lc_menu-book.svg"
                                alt="i"
                                width="22px"
                                height="22px"
                                onError={(e) => {
                                  e.target.style.display = "none";
                                }}
                                style={{ paddingBottom: "1px" }}
                              />
                              <span className="class-link">Course Detail</span>
                            </Link>
                          </div>
                          {renderJoinClassButton(extractedValues)}
                          <ModalComponent
                            type="joinClass"
                            visible={modalState.joinClass}
                            handleModal={handleModal}
                            width={440}
                            style={{ top: "50px" }}
                          >
                            {renderEnrollmentResult()}
                          </ModalComponent>
                        </Col>
                        <Col
                          className="order-3 order-sm-3 order-lg-2"
                          xs={24}
                          lg={14}
                        >
                          <ul className="d-none d-lg-block">
                            <li
                              className="class-status"
                              style={{
                                color:
                                  extractedValues?.classStatus === "In Progress"
                                    ? "#00AC47"
                                    : undefined,
                              }}
                            >
                              {extractedValues?.classStatus}
                            </li>
                          </ul>
                          <div className="class-details">
                            <p className="class-title">
                              {extractedValues?.className}
                            </p>
                            <p className="class-description">
                              {extractedValues?.description}
                            </p>
                            <Duration>
                              <p>
                                <span>
                                  <img
                                    style={{
                                      marginBottom: "5px",
                                    }}
                                    src="/icons/clock-icon.svg"
                                    alt="Clock"
                                    width="18px"
                                    height="18px"
                                  />
                                </span>
                                <span className="class-duration">
                                  {extractedValues?.classDuration}
                                </span>
                              </p>
                            </Duration>
                            <div className="class-time">
                              <p className="class-time-est">
                                {extractedValues?.estTime
                                  ? `${extractedValues?.estTime}, `
                                  : ""}
                                {formatDate(extractedValues?.classStartDate)}{" "}
                                EST
                              </p>
                              <span className="class-time-ist">
                                <ul className="ist-list">
                                  <li className="ist-item">
                                    {extractedValues?.istTime
                                      ? `${extractedValues?.istTime}, `
                                      : ""}
                                    {formatDate(
                                      extractedValues?.classStartDate
                                    )}{" "}
                                    IST
                                  </li>
                                </ul>
                              </span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </StyledCard>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default LiveClassesCard;
