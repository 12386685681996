import React, { useEffect } from "react";
import "antd/dist/antd.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./style/common.css";
import AllRouts from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import Clarity from "@microsoft/clarity";
import { GTagManager, TagManagerArgs } from "./utils/helper";

const projectId = "mnhllmrv7b";
ReactGA.initialize(GTagManager);
TagManager.initialize(TagManagerArgs);
Clarity.init(projectId);

export const AnalyticsEvents = ({ category, action, label, value }) => {
  ReactGA.event({ category, action, label, value });
  Clarity.event(category, { action, label, value });
};

export const pushPurchaseEvent = (transactionId, value, currency, items) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "purchase_event",
    transaction_id: transactionId,
    value,
    currency,
    items,
  });

  Clarity.setTag("purchase_event", {
    transaction_id: transactionId,
    value,
    currency,
    items,
  });
};

function App() {
  const currentPath = window.location.pathname + window.location.search;

  useEffect(() => {
    const triggerAnalyticsEvents = () => {
      const pageTitle = document.title;
      ReactGA.send({ hitType: "pageview", page: currentPath });
      Clarity.identify("dynamic-id", {
        sessionId: `session-${Date.now()}`,
        pageId: currentPath,
        name: pageTitle,
      });
      Clarity.event("pageview", {
        page: currentPath,
        timestamp: new Date().toISOString(),
      });
    };

    triggerAnalyticsEvents();
    const observer = new MutationObserver(triggerAnalyticsEvents);
    observer.observe(document.body, { childList: true, subtree: true });
    window.addEventListener("popstate", triggerAnalyticsEvents);
    return () => {
      observer.disconnect();
      window.removeEventListener("popstate", triggerAnalyticsEvents);
    };
  }, [currentPath]);

  return (
    <React.Fragment>
      <div className="App">
        <AllRouts />
        <ToastContainer />
      </div>
    </React.Fragment>
  );
}

export default App;
