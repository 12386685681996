import React, { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Footer from "../../../common/footer";
import { LoginContainer } from "./index.style";
import { OAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, googleProvider, msProvider } from "../../../firebase";
import { useDispatch } from "react-redux";
import { login } from "../../../redux/authSlice";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import CustomImage from "../../../common/images";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const authUser = JSON.parse(
      localStorage.getItem("authUser") &&
        localStorage.getItem("authUser") != "undefined"
        ? localStorage.getItem("authUser")
        : null
    );
    const token = authUser?.["token"];

    if (token) {
      navigate("/profile");
    }
  }, [navigate]);

  const onSignUpwithGoogle = () => {
    signInWithPopup(auth, googleProvider).then((data) => {
      const formData = {
        name: data?.user?.displayName,
        email: data?.user?.email,
        socialLogin: data?.providerId.split(".")?.[0],
        picture: data?.user?.photoURL,
      };

      dispatch(login({ formData, navigate, searchParams }));
    });
  };
  const onSignUpwithMs = () => {
    signInWithPopup(auth, msProvider)
      .then((result) => {
        const credential = OAuthProvider.credentialFromResult(result);
        // eslint-disable-next-line no-unused-vars
        const accessToken = credential.accessToken;
        // eslint-disable-next-line no-unused-vars
        const idToken = credential.idToken;
        const formData = {
          name: result?.user?.displayName,
          email: result?.user?.email,
          socialLogin: result?.providerId.split(".")?.[0],
          picture: result?.user?.photoURL,
        };
        // console.log({ formData, result, credential, accessToken, idToken });
        dispatch(login({ formData, navigate, searchParams }));
      })
      .catch((error) => {
        // Handle error.
      });
  };

  return (
    <div>
      {" "}
      <Helmet>
        <title>Tansy Academy | Login</title>
        <link rel="canonical" href="https://tansyacademy.com/login" />
      </Helmet>
      <LoginContainer>
        <div className="container">
          <div className="registratioModal">
            <h1>
              <span style={{ marginRight: "10px" }}>
                <CustomImage
                  src="/images/logo.png"
                  alt="footer-logo"
                  width="50px"
                  height="50px"
                />
              </span>
              Login
            </h1>
            <p>
              Welcome to Tansy Academy,
              <br /> your destination for online SQL training.
            </p>
            <div className="registerLinks">
              <div className="linkItem" onClick={onSignUpwithGoogle}>
                <div className="inner">
                  <CustomImage
                    src="/images/googleImage.svg"
                    alt="i"
                    width="24px"
                    height="24px"
                    onError={(e) => {
                      e.target.style.display = "none";
                    }}
                  />
                  <span>Continue with Google</span>
                </div>
              </div>
              {/* <div className='linkItem'>
                <div className='inner'>
                  <CustomImage src='/images/linkdInImage.svg' alt="i"  width="100%"  height="100%"                       onError={(e) => {e.target.style.display = 'none';}} />
                  <span>Continue with LinkedIn</span>
                </div>
              </div> */}
              {/* <div className='linkItem'>
                <div className='inner'>
                  <CustomImage src='/images/facebookImage.svg' alt="i"  width="100%"  height="100%"                       onError={(e) => {e.target.style.display = 'none';}} />
                  <span>Continue with Facebook</span>
                </div>
              </div> */}
              <div className="linkItem" onClick={onSignUpwithMs}>
                <div className="inner">
                  <CustomImage
                    src="/images/microsoftImage.svg"
                    alt="i"
                    width="24px"
                    height="24px"
                    onError={(e) => {
                      e.target.style.display = "none";
                    }}
                  />
                  <span>Continue with Microsoft</span>
                </div>
              </div>
            </div>
            <div className="login">
              <p>
                New here? <Link to="/register">Register</Link> Now
              </p>
            </div>
          </div>
        </div>
      </LoginContainer>
      <Footer />
    </div>
  );
};

export default Login;
